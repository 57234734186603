var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "work-effort-removal-warning" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "work-effort-removal-warning-modal shadow",
            "modal-class": "anti-shift",
            title: _vm.$t("task.confirmation.title_work_effort_warning"),
          },
          on: {
            hidden: function ($event) {
              return _vm.hidden()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  _vm.enableApplyAll
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyAll,
                            callback: function ($$v) {
                              _vm.applyAll = $$v
                            },
                            expression: "applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.ok(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.ok(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [_vm._v(_vm._s(_vm.statement))]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="work-effort-removal-warning">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" 
      no-close-on-backdrop  content-class="work-effort-removal-warning-modal shadow" modal-class="anti-shift"
      :title="$t('task.confirmation.title_work_effort_warning')"
      @hidden="hidden()"
    >
      <div class="d-block">
        <p>{{ statement }}</p>
      </div>

      <template v-slot:modal-footer="{ }">
        <b-form-checkbox v-if="enableApplyAll" class="apply-to-all" v-model="applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok(true)">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="ok(false)">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import { objectClone } from '@/helpers'

export default {
  name: 'TaskStaffWithWorkEffortRemovalWarningModal',
  components: {
    
  },
  props: {
    show: { 
      type: Boolean
      , required: true
    },
    taskId: {
      type: String
      , default: null
    },
    taskName: {
      type: String
      , default: null
    },
    enableApplyAll: {
      type: Boolean
      , default: false
    },
    customStatementCode: {
      type: String
      , default: null
    }
  },
  data() {
    return {
      modalShow: false
      , applyAll: false
      , okClicked: false
    }
  },
  created() {
    
  },
  mounted() {
    this.modalShow = this.show
  },
  beforeDestroy() {
  },
  computed: {
    statement() {
      return this.taskName == null || this.taskName.trim().length == 0
      ? this.$t(this.customStatementCode != null? this.customStatementCode : 'task.confirmation.work_effort_warning') 
      : this.$t(this.customStatementCode != null? this.customStatementCode : 'task.confirmation.work_effort_warning_with_arg', [this.taskName]);
      // return this.taskName == null || this.taskName.trim().length == 0
      // ? this.$t('task.confirmation.work_effort_warning') 
      // : this.$t('task.confirmation.work_effort_warning_with_arg', [this.taskName]);
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue
      }
    }
  },
  methods: {
    ok(userAgreed=false) {
      // console.log('ok logic1 call hidden(true)') //eslint-disable-line no-console
      this.okClicked = true;
      this.$emit('update:show', false)
      this.$emit('ok', { taskId: this.taskId, userAgreed: userAgreed, applyAll: this.applyAll })
    },
    
    hidden() {
      // console.log('hidden logic1 this.okClicked', this.okClicked) //eslint-disable-line no-console
      if (!this.okClicked) {
        this.$emit('cancel')
      }
      this.okClicked = false;
      this.$emit('update:show', false)
    }
  }
}
</script>
<style>
</style>